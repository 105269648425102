@import '../variables';

.main-wrapper{

    .row {
    
        .home-text-container{ 
            // margin-top: -12rem;
            // @media screen and (min-width: 1500px) {
            //     margin-top: -12rem;
            // }
            // @media screen and (min-width: 1024px) {
            //     margin-top: -8rem;
            // }
            // @media screen and (min-width: 768px) {
            //     margin-top: -10rem;
            // }
            // desktop liftoff logo
            img.d-sm-block {
                max-width: 380px;
                margin-bottom: .8em;
            }
            // mobile liftoff logo
            img.d-sm-none {
                max-width: 240px;
                margin-bottom: .8em;
            }
            img#zerog {
                max-width: 185px;
            }

            .heading-text {
                text-transform: uppercase;
                font-size: 1.1em;

                .unlock-the-secret {
                    font-size: 1em;
                }

                .prize-money {
                    line-height: 1.1; 
                    font-size: 2.5em;
                    margin: 2em auto 0; 
                    @media screen and (max-width: 575.9px) {
                        margin-bottom: 2em;
                    }

                    img { 
                        display: block; max-width: 266px; margin: -0.3em auto -0.2em;
                    }


                    span {
                        display: block;
                        &:first-of-type { font-size: .55em;}
                        
                    }
                    sup {
                        font-size: .5em;
                        vertical-align: .8em;
                        top: 0;
                    }
                    small {
                        font-size: .7em;
                        font-weight: 700;
                    }
                }

                .plus-cash {
                    font-size: 1.6em;
                    font-family: $body-font;
                    font-style: normal;
                    font-weight: normal;
                    text-transform: none;;
                }

                .logos {
                    span { display: inline-block; }
                    span img { width: 50%; height: auto; }
                    @media screen and (max-width: 575.9px) {
                        display: flex;
                    }
                }
            }

            .home-header {
                font-family: $heading-font;
                text-align: center;
                margin: 30px 0;
                text-transform: uppercase;

            }

            .home-body-text{
                font-family: $body-font;
                font-size: 1.6em;
                text-align: center;
                font-weight: normal;
                // margin: 1em 0;

                a { color: $white; }
                @media screen and (min-width:576px) {
                    max-width: 60%;
                    margin: 0 auto;
                }
            }

            

            .insta-link {
                width: 2em;
                height: 2em;
            }
            
            .comingsoon-body {
                font-size: 1.2em;
            }
            .closed-body {
                font-size: 1.2em;
                margin-top: 3em;
            }
            &.closed-pad {
                @media screen and (min-width: 576px) {
                    margin-top: 6rem;
                }
            }
        }
        &#howtoenter {
            text-align: center;
            padding: 3em 0;
           
            div {
                img {
                    // height: 150px;
                    object-fit: contain;
                    // margin-bottom: 1em;
                    &.overflow {
                        margin-top: -1em;
                        margin-bottom: 1em;
                        // height: calc(150px + 2em);
                    }
                }
            }

            h2, h3 {
                text-transform: uppercase;         
            }
            h2 {
                margin-bottom: 1em;
            }
            h3 {
                font-size: 1em;
                margin-bottom: .5em;
            }

            p {
                max-width: 500px;
                margin: 0 auto;
                font-weight: normal;
                small {
                    display: block;
                }
            }
        }

        
    }

    .coming-soon .row .home-text-container .heading-text .prize-money {
        margin: 1em auto;
    }
    .coming-soon .video-frame {
        // margin-top: 4em;
    }

    .container.disclaimer-wrap { padding-bottom: 3em; }
    .disclaimer {

        p {
            a{
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}



//french version
.fr-CA{
    .main-wrapper .full-Vh .home-text-container .heading-text,
    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-the-secret,
    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        

        @media screen and (max-width: 575.9px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-win{
        @media screen and (max-width: 575.9px) {
            
        }
    }

    .main-wrapper .full-Vh .home-text-container .enter-btn-container button p{

        width: 100%;
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        @media screen and (max-width: 575.9px) {

        }
    }
    .main-wrapper .row .home-text-container img.d-sm-none {
        max-width: 100%;
    }
    .main-wrapper .row .home-text-container img.d-sm-block {
        // max-width: 520px;
    }

    .main-wrapper .row .home-text-container .heading-text .prize-money img {
        width: 575px;
        max-width: 100%;
        @media screen and (max-width: 575.9px) {
            margin: -.3em auto -.15em;
        }
    }
    .main-wrapper .row {
        .coming-soon-text-container, .home-text-container {
            .heading-text {
                .prize-money {
                    @media screen and (max-width: 1199px) {
                    }

                    @media screen and (max-width: 991px) {
                        
                    }

                    @media screen and (max-width: 575.9px) {
                    }
                }
                .plus-cash {
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 991px) {
                    }
                    @media screen and (max-width: 575.9px) {
                    }
                }
            }
        }
    }
}

.fullwidth {
    width: 100vw;
    margin: 0 -7.25rem;
    padding: 3rem;
}

.video-frame {
    max-width: 830px;
    margin: 2em auto 0;

    background: rgba(15,9,103,0.81);
    box-shadow: 0px 0px 26px #0089FF;
    border: 4px solid #023ADA;
    border-radius: 8px;
    opacity: 0.91;
    
    padding: 1em;

    video { cursor: pointer; }

    @media screen and (max-width: 575.9px) {
        padding: .5em;

        video {
            aspect-ratio: 385/314;
            object-fit: cover;
        }
    }
}

.chevrons {
    text-align: center;
    margin: 2em auto;

    a span {
        background-image: url(../../images/chevron.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 40px auto;
        height: 40px;
        display: block;
        position: relative;
        margin-top: -22px;
        animation: bop 1s alternate infinite ease-in-out;
    }
    a span:nth-child(1) { 
        opacity: .33;
        animation-delay: .2s;
    }
    a span:nth-child(2) {
        opacity: .66;
        animation-delay: .1s;
    }

    @keyframes bop {
        0% { transform: translateY(0); }
        10% { transform: translateY(0); }
        90% { transform: translateY(10px); }
        100% { transform: translateY(10px); }
    }
}