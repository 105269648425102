.dunk-frame {
    position: relative;

    .glass {
        padding-top: 16em;
        overflow: hidden;

        img {
            width: 474px;
            margin-left: 27px;

            &#fill, &#splash {
                width: 296px;
                position: absolute;
                left: 50%;
                margin-left: calc(-198px + 63px);
                top: calc(16em - 70px);

                display: none;
            }

            @media screen and (max-width: 540px) {
                margin-left: calc(50vw - 248px);
            }
        }

        &.dunked {
            img#fill, img#splash {
                display: block;
            }
            img#splash {
                animation: splash-up 0.5s;
            }
        }
    }
    .chevrons {
        position: absolute;
        top: 230px;
        left: 0;
        right: 0;
        z-index: 1;
        a {
            display: block;
            width: 40px;
            margin: 0 auto;
            &:focus {
                outline: 1px dashed white;
            }
        }
    }
    .cookie-carousel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        touch-action: none;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none !important;
        img {
            width: 270px;
            margin-left: -135px;
            position: absolute;
            top: 0;
            user-select: none;
            -webkit-user-select: none !important;

            &.s0:focus {
                outline: 1px dashed white;  
            }
        }
    }

}

@keyframes splash-up {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}