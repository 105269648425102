@import '../variables';

.main-wrapper{

    .page-body.faq {
        text-align: left;

        > p{
            padding: 0 0 1.5em;
        }

        a{
            color: inherit;
            text-decoration: underline;
        }

        .qa{
            padding: 0 0 1.5em;

            .q{
                margin-bottom: .5em;
                font-family: $body-font;
                font-style: normal;
                font-weight: normal;
                text-transform: none;
            }

            ul{
                padding-left: 20px;
                padding-top: 20px;
                list-style-type: disc;
                list-style-position: outside;
            }

            .a { 
                padding: 0;
                font-family: sans-serif;
                font-size: .9em;
                line-height: 1.3;
            }
        }

        + .disclaimer, + .text-center + .disclaimer {
            margin-top: 4em;
            margin-bottom: -4em;
        }
    }
}