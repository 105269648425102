@import '../variables';

.main-wrapper.prize-details  {
    p { font-size: 1.2em; }
    .prize-details__text-section{
        align-items: center;
        position: relative;
        

        padding-left: .5em; padding-right: .5em; 

        @media screen and (min-width: 768px) {     
            
            margin-bottom: 6em;  
            
            // background: transparent url('img/Rectangle 46.png') 0% 0% no-repeat padding-box;
            background: rgba(15,9,103,0.81);
            box-shadow: 0px 0px 26px #0089FF;
            border: 4px solid #023ADA;
            border-radius: 8px;
            opacity: 0.91;
            
            padding: 1em;
        }
       
        h2, h4 {
            font-style: normal;
            line-height: 1em;

            @media screen and (max-width: 767.9px) {
                text-align: center;
            }
        }
        h2 {
            text-transform: uppercase;
            font-family: $heading-font;
            font-size: 1.2em;
        }
        h4 { font-weight: bold; }

        p{
            margin-top: 1em;
            @media screen and (max-width: 767.9px) {
                text-align: center;
            }
        }
        &.grand-prize .col-md-6 {
            div {margin: 1.5rem 0;}
            &.img { text-align: center; }
            img {
                // margin-top: 3rem;
                // @media screen and (max-width: 768px) {
                //     margin-top: .5rem;
                // }
                &:first-child {
                    object-fit: cover;
                    aspect-ratio: 4.4/3;
                }
                &:last-child {
                    margin: 1em auto;
                }
            }
        }
        &:not(.grand-prize) {
           
            @media screen and (min-width: 768px) {    
                margin-bottom: 1em;
            }

            p {
                width: 80%;
            }
            @media screen and (max-width: 767.9px) {
                flex-direction: column-reverse;
                p { width: 100%; }
            }
            .img {
                // overflow: hidden;
                img {
                    margin: -2em auto;
                    // width: calc(100% + 100px);
                    // max-width: unset;
                    // @media screen and (max-width: 768px) {
                    //     &:last-of-type {
                    //       margin-bottom: -30px;  
                    //     }
                    // }
                }
            }
        }

        .img{
            @media screen and (max-width: 767.9px) {
                margin: 2em auto;
            }

            img{
                max-width: 100%;
            }
        }

        ul{
            list-style-type: disc;
            margin-left: 20px;

            li{
                margin: 10px 0;
            }
        }
       h4, p {
            margin: .25rem 0;
        }

        

        @media screen and (min-width: 768px) {
            &.grand-prize .cookie {
                content:'';
                width: 12em;
                height: 12em;
                display: block;
                background: url(../../images/cookie5.png);
                background-size: contain;
                position: absolute;
                left: -8em;
                top: -7em;

                @media screen and (max-width: 1023.9px) {
                    left: -10em;
                }
            }
            &:not(.grand-prize) .cookie {
                content:'';
                width: 12em;
                height: 12em;
                display: block;
                background: url(../../images/cookie4.png);
                background-size: contain;
                position: absolute;
                right: -4em;
                top: -2em;

                @media screen and (max-width: 1023.9px) {
                    right: -6em;
                }
            }
        }
    }

    .packshots { 
        img { 
            max-height: 340px;
            max-width: 90%;
        }
        margin: 2em auto;
    }
    .page-body {
        p {
            text-align: center;
            @media screen and (min-width:768px){
                width: 80%;
                margin: 0 auto;
            }
        }
        
        .text-center {
            .enter-btn {
                margin: 1.5em auto;
            }
        }
    }
    .product-list {
        p { column-count: 2; }
        margin-bottom: 2em;
        margin-top: 2em;
    }
}

