@import '../variables';

//PIN form

.pin-form__container, .info-form__container, .stq-form-container {


    @media screen and (min-width: 768px) {
        position: relative;
    }
}
.pin-form__container{

    .form__heading{
        color: white;
        text-transform: none;
        font-family: $heading-font;
    }

    img {
        width: 100%;
        height: 6em;
        object-fit: cover;
        object-position: bottom;
        margin-top: 1em;
    }

    .form__description{
        color: white;
        margin: 20px auto;
        font-size: 1.2em;
        
        a { text-decoration: underline; color: $white; }
        small { display: block; }
    }

}

//error message
.pin-not-found {
    position: fixed;
    bottom: 64px;
    padding: 30px;
    background: #dd0000;
    border-radius: 6px;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    left: 50%;
    transform: translate(-50%, 30px);
    display: flex;

    @media screen and (max-width:600px) {
        bottom: 40px;
        width: calc(100% - 20px);
    }

    button{
        background: $white;
        border-radius: 30px;
        color: #ff1606;
        margin-left: 20px;
        border: none;
    }
}

//info form
// .main-wrapper .info-form__container {
//     h1 {margin-top: 0px;}
// }
.info-form__container{
    padding-bottom: 100px;

    .form__heading{
        color: white;
        text-transform: uppercase;
        font-family: $heading-font;
    }

    .form__description{
        color: white;
        font-size: 1.2em;
        margin: 20px auto 40px auto;
    }

    .info-form {
        max-width: 650px;
        margin: 0 auto;

        .checkbox-input {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            margin: 1rem 0;
            gap: .75rem;
            .MuiIconButton-label {
                span {
                    position: absolute;
                    top: 0;
                }
            }
            .PrivateSwitchBase-root-1 {padding: 0px;}
            .checkbox-input__label{
                font-family: sans-serif;
                font-size: .9em;
                color: $white;
                line-height: 1;

                a{
                    color: inherit;
                    text-decoration: underline;
                }
            }

            + .infoForm__errorMessage{
                position: relative;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                line-height: 1.4;
                text-align: left;
                padding-left: 30px;
                margin: 3px 0;

                &:empty{
                    display: none;
                }

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    // background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }
        }

        .info-form__legal{
            text-align: left;
            color: $white;
            line-height: 1;
            font-family: sans-serif;
            font-size: .9em;

            margin-bottom: 40px;
            margin-left: 40px;
        }

    }
}