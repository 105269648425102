@font-face {
    font-family: 'Helvetica';
    src: url('/fonts/helveticaneue-01-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Logik';
    src: url('/fonts/logik_regular-webfont.woff') format('woff'), url('/fonts/logik_regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Logik';
    src: url('/fonts/logik_semibold-webfont.woff') format('woff'), url('/fonts/logik_semibold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}