@import './fonts.scss';

$grey : #00000029;
$white : #ffffff;
$black: #000000;
$blue: #023ADA;
$lightblue: #0089FF;
$pink: #EA239F;
$purple: #070522CE;

$pink-purple: transparent linear-gradient(159deg, #AC009E 0%, #04006F 100%) 0% 0% no-repeat padding-box;


//fonts
$heading-font : "Logik", sans-serif;
$body-font : "Logik", sans-serif;