@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .key-container{
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 75%;
        }
    }

    .you-win.stq-form-container {
        text-align: left;
        margin: auto;
    }

    .you-win{
        
        text-align: center;

        // padding-top: 100px;
        // padding-left: 2rem;
        // padding-right: 2rem;
        h1{
            font-family: $heading-font;
            text-transform: uppercase;
            margin-bottom: .5em;
        }

        h3 { font-family: $body-font;
            font-weight: normal;
            font-style: normal; }

        > img {
            max-width: 66%;
            margin: 0 auto 1em;
            @media screen and (max-width: 575.9px) {
                max-width: 100%;
            }
        }
        button, .enter-btn { margin: 2rem auto; display: block; }   
           
        p{
        
            // text-align: left;

            a{
                color: $white;
                text-decoration: underline;
            }
            &.legal {
                // font-size: .75em;
                // max-width: 75%;
                width: 100%;
            }
        }


        span{
        }

        .social-container{
            margin-bottom: 2em;
            
            img{
                margin: 0 10px;
                max-width: 44px;
            }
        }

        &.lose {
            h3 {
                margin-top: 2em;
                margin-bottom: 2em;
            }
        }

        .packshots {
            margin: 2em auto;
        }

        .info-form__legal {
            font-family: sans-serif;
            font-size: .9em;
            text-align: left;
        }
    }
}