#bg {
    overflow-x: clip;
    position: relative;

    &:before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url('../images/bg.jpg');
        background-size: cover;
        -webkit-backface-visibility: hidden;
    }

    @media screen  and (min-width: 768px) {
        .flare { // cookie flare at top of page
            // &:before {
                width: 15em;
                height: 15em;
                content: '';
                background: url(../images/cookie-lensflare.png);
                background-size: contain;
                position: absolute;
                left: calc(50vw - 32em);
                top: -6em;
            // }
        }
    }
    @media screen and (max-width: 767.9px) {
        // cookie flare at top of page
        &:has(~ .main-wrapper > .home) .flare:before {
            width: 12em;
            height: 12em;
            content: '';
            background: url(../images/cookie-lensflare.png);
            background-size: contain;
            position: absolute;
            left: 3em;
            top: -5em;
        }
    }
    
    &:has(~ .main-wrapper > .home),
    &:has(~ .main-wrapper.bg-both-dt),
    &:has(~ .main-wrapper.bg-left-dt) {

        .floater1 {
            width: 6em;
            height: 6em;
            content: '';
            background: url(../images/cookie5.png);
            background-size: contain;
            position: absolute;
            left: -1em;
            top: 8em;
            @media screen  and (min-width: 768px) {
                width: 8em;
                height: 8em;
                left: calc(50vw - 36em);
                top: 20em;
            }
        }

        .fr-CA & {
            .floater1 {
                top: 8em;
                @media screen and (max-width: 575.9px) {
                    top: 5em;
                    left: -3.6em;
                }
            }
        }
    }

    &:has(~ .main-wrapper > .home),
    &:has(~ .main-wrapper.bg-both-dt),
    &:has(~ .main-wrapper.bg-right-dt)  {
        .floater2 {
            width: 6em;
            height: 6em;
            content: '';
            background: url(../images/cookie4.png);
            background-size: contain;
            position: absolute;
            right: -.5em;
            top: 19em;
            @media screen  and (min-width: 768px) {
                width: 8em;
                height: 8em;
                right: calc(50vw - 36em);
                top: 12em;
            }
            @media screen and (max-width: 575.9px) {
                top: 13em;
            }
        }

        .fr-CA & {
            .floater2 {
                top: 19em;
                @media screen and (max-width: 575.9px) {
                    top: 13em;
                }
            }
        }
    }

    &:has(~ .main-wrapper.bg-both-dt),
    &:has(~ .main-wrapper.bg-left-dt),
    &:has(~ .main-wrapper.bg-right-dt) {
        @media screen and (max-width: 767.9px) {
            .floater1, .floater2 { display: none; }
        }
    }
}


.cookie-wrap { position: relative; }
.floating-cookie1, .floating-cookie2, .floating-cookie3, .floating-cookie4 {
    width: 10em;
    height: 10em;
    display: block;
    background: url(../images/cookie1-angle.png);
    background-size: contain;
    position: absolute;
    left: calc(50% + 1em);
    top: -7em;

}
.floating-cookie1 {
    .home & {
        width: 6em;
        height: 6em;
        left: calc(50% - 17em);
        top: unset;
        bottom: -6em;
        @media screen and (max-width: 575.9px) {
            width: 4em;
            height: 4em;
            left: calc(50% - 4em);
            bottom: -5em;
        }
    }
}
.floating-cookie2 {
    width: 12em;
    height: 12em;
    background-image: url(../images/cookie4-angle.png);
    left: calc(50% - 22em);
    top: unset;
    bottom: -2em;
    @media screen and (max-width: 575.9px) {
        left: calc(50% - 20em);
    }
    .home & {
        left: calc(50% - 24em);
        top: -6em;
        bottom: unset;
        @media screen and (max-width: 575.9px) {
            width: 8em;
            height: 8em;
            left: 0;
            top: -9em
        }
    }
    .fr-CA .home & {
        left: calc(50% - 30em);
        @media screen and (max-width: 575.9px) {
            left: 0;
        }
    }
}
.floating-cookie3 {
    width: 6em;
    height: 6em;
    background-image: url(../images/cookie2-angle.png);
    left: calc(50% + 10em);
    top: -1em;
    @media screen and (max-width: 575.9px) {
        left: calc(50% + 8em);
    }
}
.floating-cookie4 {
    width: 6em;
    height: 6em;
    background-image: url(../images/cookie2.png);
    left: calc(50% + 22em);
    top: unset;
    bottom: -4em;
    @media screen and (max-width: 575.9px) {
        width: 5em;
        height: 5em;
        left: calc(50% + 5em);
        top: -6em;
        bottom: unset;
    }
}


.bobber {
    animation: bob 3s infinite alternate ease-in-out;
}
.bobber.slow {
    animation: bob 5s infinite alternate ease-in-out;
}
.bobber.reverse {
    animation: bob 3s infinite alternate-reverse ease-in-out;
}
@keyframes bob {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(1.5em);
    }
}
.bobber.spin {
    animation: spinandbob 10s infinite linear;
}
@keyframes spinandbob {
    0% {
        transform: rotate(0deg) translateY(2em);
    }
    50% {
        transform: rotate(180deg) translateY(0);
    }
    100% {
        transform: rotate(360deg) translateY(2em);
    }
}